import {
  TextField,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Box,
  Autocomplete,
  Chip,
} from "@mui/material";
import { isCurrency, isEmpty } from "../util/Validation";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  ROLES,
  LANGUAGES,
  SERVICES,
  DOWNLOAD,
  SKILLS,
} from "../util/Routes/Routes";
import { messageError, messageSuccess } from "../util/Toast";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import Get from "../util/Request/Get";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UppyComponent, {
  uppy,
  setEndPoint,
  upload,
} from "../components/Uppy/Uppy";
import { useTranslation } from "react-i18next";
import TabsComponent from "../components/Tabs/Tabs";
import Post from "../util/Request/Post";
import Put from "../util/Request/Put";
import { backend } from "../util/Environment";
import BaseModal from "./BaseModal";
import i18next from "i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minWidth: "50%",
  overflowY: "auto",
  maxHeight: "90vh",
};

//import MenuBarMd from "../components/Form/MenuBarMd/MenuBarMd";

export default function ServicesModal({ open, onClose, data: serviceData }) {
  const [price, setPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const [showFrontPage, setshowFrontPage] = useState(true);
  const [active, setActive] = useState(true);
  const [order, setOrder] = useState("");
  const [nbType, setNbType] = useState("");

  const [translationText, setTranslationText] = useState({});
  const [tabErrors, setTabErrors] = useState({});
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const resetFields = () => {
    uppy.cancelAll();
    uppy.setMeta({});
    setPrice(0);
    setErrors({});
    setshowFrontPage(true);
    setActive(true);
    setOrder("");
    setNbType("");
    setTranslationText({});
    setTabErrors({});
  };
  const getServiceById = () => Get({ route: SERVICES, key: serviceData?.id });
  const {
    data: service,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["service", serviceData?.id],
    queryFn: getServiceById,
    enabled: false,

    onError: (error) => {
      messageError();
    },
  });

  const downloadServiceImage = (service) =>
    Get({
      route: DOWNLOAD,
      key: service?.service.card_img,
    });

  const { refetch: fetchImage } = useQuery({
    queryKey: ["serviceImage", service],
    queryFn: ({ queryKey }) => downloadServiceImage(queryKey[1]),
    enabled: false,
    onSuccess: (blob) => {
      const image = service.service.card_img;
      const file = new File([blob], image, {
        type: blob?.type,
      });
      uppy.addFile({
        name: image,
        type: blob?.type,
        data: file,
        preview: `${backend}${image}`,
      });
    },
    onError: (error) => {
      messageError();
    },
  });

  /* Create request */
  const createService = (translations) =>
    Post({
      route: SERVICES,
      body: {
        price,
        role_id: type,
        translations: JSON.stringify(translations),
        active: active,
        front_page: showFrontPage,
        skills_ids: skillsArray,
      },
    });

  /* Update request */
  const updateService = (translations) =>
    Put({
      route: SERVICES,
      key: service.service.id,
      body: {
        price,
        translations: JSON.stringify(translations),
        active: active,
        front_page: showFrontPage,
        order,
        skills_ids: skillsArray,
      },
    });

  const mutation = useMutation({
    mutationFn: (translations) =>
      serviceData ? updateService(translations) : createService(translations),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["services"] });
      queryClient.setQueryData(["service", serviceData?.id], data.service);
      onClose();
      messageSuccess(
        serviceData ? "updated successfully" : "created successfully"
      );
    },
    onError: (error, variables, context) => {
      messageError(serviceData ? "failed to update" : "failed to create");
    },
    onSettled: (data, error, variables, context) => {},
  });

  // get service data on edit
  useEffect(() => {
    if (open && serviceData) {
      refetch();
    }
  }, [open, refetch, serviceData]);

  // reset field on modal close
  useEffect(() => {
    resetFields();
  }, [open]);

  //fill the fields when opening modal on edit
  useEffect(() => {
    if (service?.service && open) {
      if (service.service.card_img && !uppy.getFiles().length) {
        fetchImage();
      }
      setPrice(service.service.price);
      setType(service.service.role_id);
      setActive(service.service.active);
      setOrder(service.service.order);
      setshowFrontPage(service.service.front_page);
      setNbType(service.nbType);
      setSkillsArray(service?.service.skills_ids);
      const translations = service.translations.reduce(
        (accumulator, { language_code, label, value }) => {
          const split = label.split(".");
          if (!accumulator[language_code]) {
            accumulator[language_code] = {};
          }

          accumulator[language_code][split[split.length - 1]] = value;
          return accumulator;
        },
        {}
      );

      setTranslationText(translations);
    }
  }, [service, open, fetchImage]);

  const getLanguages = () => Get({ route: LANGUAGES });
  const { data: languagesData } = useQuery({
    queryKey: ["languages"],
    queryFn: getLanguages,
    onError: (error) => {
      messageError();
    },
  });

  const getRoles = () => Get({ route: ROLES });
  const { data } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    onError: (error) => {
      messageError();
    },
  });

  /* SKILLS */
  const [skillsArray, setSkillsArray] = useState([0, 0, 0]);
  // Définition de la fonction pour récupérer les compétences en fonction de la langue
  const getSkillsByLanguage = () =>
    Get({
      route: `${SKILLS}/getTitlesByLanguage/${i18next.language}`,
    });

  // Utilisation de useQuery pour effectuer la requête et récupérer les données des compétences
  const { data: { list: skillsDataList } = {} } = useQuery({
    queryKey: ["skills"],
    queryFn: getSkillsByLanguage,
    onError: messageError, // Gestion de l'erreur avec la fonction messageError
  });

  // Utilisation de useMemo pour mémoriser les options de compétences
  const skillsOptions = useMemo(() => {
    // Vérification si skillsDataList est défini
    if (!skillsDataList) {
      return []; // Retourne un tableau vide si skillsDataList est undefined ou null
    }

    // Mapping des compétences en ajoutant la propriété "id" correspondant au numéro extrait de "label"
    return skillsDataList.map((skill) => ({
      ...skill,
      id: parseInt(skill.label.split(".")[1], 10), // création de l'id
    }));
  }, [skillsDataList]);

  // Initialisation de l'état "skills"
  const handleSkillsChange = (selectedSkills) => {
    // Créez un tableau mis à jour avec les compétences sélectionnées et les positions vides remplies de 0
    const updatedSkillsArray = [
      ...selectedSkills, // Compétences sélectionnées
      ...Array(3 - selectedSkills.length).fill(0), // Positions vides remplies de 0
    ];

    // Mettez à jour skillsArray avec le tableau mis à jour
    setSkillsArray(updatedSkillsArray);
  };

  // Utilisation de useEffect pour définir le type à partir de skillsOptions
  useEffect(() => {
    // Vérification si skillsOptions est défini et a une longueur supérieure à zéro
    if (skillsOptions.length > 0) {
      setType(skillsOptions[0]?.id); // Définition du type en utilisant le premier élément de skillsOptions
    }
  }, [skillsOptions]);

  // init translations json on create, using the languages as keys
  useEffect(() => {
    if (languagesData && !serviceData) {
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        if (!translationText[iso_code]) {
          translationText[iso_code] = {};
        }
      });
      setTranslationText(translationText);
    }
  }, [languagesData, translationText, serviceData]);

  const typeOptions = useMemo(() => {
    return (
      data?.list.filter(
        ({ label }) => label !== "admin" && label !== "moderator"
      ) || []
    );
  }, [data]);
  const [type, setType] = useState("");
  useEffect(() => {
    if (typeOptions && !service) {
      setType(typeOptions[0]?.id);
    }
  }, [typeOptions, service]);

  const validation = useCallback(() => {
    Object.keys(translationText)?.forEach((language) => {
      errors[language] = {};

      tabErrors[t(`shared.language.${language}`)] = {};

      if (isEmpty(translationText[language].title)) {
        errors[language].title = "required";
      } else {
        delete errors[language]?.title;
      }
      const quotes = Object.values(translationText).map(({ quote }) => quote);

      const otherLanguages = Object.keys(translationText).filter(
        (element) => element !== language
      );

      if (quotes.every((quote) => quote === undefined || quote === "")) {
        Object.keys(translationText).forEach((language) => {
          delete errors[language]?.quote;
        });
      } else if (!isEmpty(translationText[language].quote)) {
        otherLanguages.forEach((language) => {
          if (isEmpty(translationText[language].quote)) {
            errors[language] = {};
            tabErrors[t(`shared.language.${language}`)] = {};
            errors[language].quote = "required";
          } else {
            delete errors[language]?.quote;
            delete tabErrors[t(`shared.language.${language}`)];
          }
        });
      }
      if (isEmpty(translationText[language].description)) {
        errors[language].description = "required";
      } else {
        delete errors[language]?.description;
      }
      if (errors[language] && !Object.values(errors[language])?.length) {
        delete errors[language];
        delete tabErrors[t(`shared.language.${language}`)];
      }
    });
    setTabErrors({ ...tabErrors });
    setErrors({ ...errors });
  }, [translationText, t, tabErrors, errors]);

  const tabContent = useMemo(() => {
    const object = {};

    if (languagesData) {
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        object[t(`shared.language.${iso_code}`)] = (
          <Grid container spacing={2} direction={"column"}>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_title`)}
                fullWidth
                value={translationText[iso_code]?.title || ""}
                onChange={(e) => {
                  translationText[iso_code].title = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.title}
                helperText={errors[iso_code]?.title}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_quote`)}
                fullWidth
                value={translationText[iso_code]?.quote || ""}
                onChange={(e) => {
                  translationText[iso_code].quote = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.quote}
                helperText={errors[iso_code]?.quote}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_paragraph`)}
                rows={4}
                multiline
                fullWidth
                value={translationText[iso_code]?.description || ""}
                onChange={(e) => {
                  translationText[iso_code].description = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.description}
                helperText={errors[iso_code]?.description}
              />
            </Grid>
          </Grid>
        );
      });
    }

    return object;
  }, [languagesData, t, translationText, errors, validation]);

  const onSubmit = () => {
    validation();

    if (!Object.values(errors).length) {
      // use uppy to create/update if there is an image, else use mutate
      if (uppy.getFiles().length) {
        uppy.setMeta({
          price,
          role_id: type,
          translations: JSON.stringify(translationText),
          active: Number(active),
          front_page: Number(showFrontPage),
          _method: service ? "PUT" : "POST",
          "content-type": uppy.getFiles()[0].type,
          order,
          skills_ids: JSON.stringify(skillsArray),
        });
        setEndPoint(`${SERVICES}${service ? '/' + service?.service.id : ""}`);
        upload(onClose, () => {
          queryClient.invalidateQueries({
            queryKey: ["services"],
          });
          queryClient.setQueryData(["service", serviceData?.id], data.service);
        });
      } else {
        mutation.mutate(translationText);
      }
    }
  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        {isFetching ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <h2>{!service ? "Create service" : "Update service"}</h2>
            <Grid container spacing={2} direction={"column"}>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  value={price}
                  error={!!errors.price}
                  helperText={errors.price}
                  onChange={(e) => {
                    const price = e.target.value;
                    setPrice(price);
                    if (!isCurrency(e.target.value)) {
                      errors.price = "format invalid";
                    } else {
                      delete errors.price;
                    }
                    setErrors({ ...errors });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label={"price"}
                />
              </Grid>
              <Grid xs={12}>
                <UppyComponent
                  maxNumberOfFiles={1}
                  allowedFileTypes={["image/*"]}
                />
              </Grid>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="service-type-label">type</InputLabel>
                  <Select
                    labelId="service-type-label"
                    id="service-type-select"
                    value={type}
                    label="type"
                    disabled={service ? true : false}
                    onChange={(e) => setType(e.target.value)}
                  >
                    {typeOptions?.map(({ label, id }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="service-status-label">status</InputLabel>
                  <Select
                    defaultValue={true}
                    labelId="service-status-label"
                    id="service-status-select"
                    value={active}
                    label="status"
                    onChange={(e) => setActive(e.target.value)}
                  >
                    <MenuItem value={true}>active</MenuItem>
                    <MenuItem value={false}>inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="service-front-page-label">
                    show in front page
                  </InputLabel>
                  <Select
                    defaultValue={true}
                    labelId="service-front-page-label"
                    id="service-front-page-select"
                    value={showFrontPage}
                    label="show in front page"
                    onChange={(e) => setshowFrontPage(e.target.value)}
                  >
                    <MenuItem value={true}>show</MenuItem>
                    <MenuItem value={false}>hide</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <Autocomplete
                  multiple
                  id="skills-autocomplete"
                  options={skillsOptions}
                  getOptionLabel={(option) => option.value}
                  value={skillsOptions.filter((option) =>
                    skillsArray.includes(option.id)
                  )}
                  onChange={(event, newValue) => {
                    // Récupère les compétences sélectionnées sous forme d'ID
                    const selectedSkills = newValue.map((option) => option.id);

                    // Vérifie si le nombre de compétences sélectionnées est inférieur ou égal à 3
                    if (selectedSkills.length <= 3) {
                      handleSkillsChange(selectedSkills); // Mettez à jour skillsArray avec les compétences sélectionnées
                    }

                    // Vérifie si aucune compétence n'est sélectionnée
                    if (selectedSkills.length === 0) {
                      handleSkillsChange([0, 0, 0]); // Met à jour skillsArray avec trois positions vides
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("nav.skills")}`} // Étiquette du champ de saisie
                      placeholder={`${
                        3 - skillsArray.filter((value) => value !== 0).length
                      } ${t("skill.remaining")}`} // Placeholder avec le nombre de compétences restantes
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.value} // Libellé de chaque tag de compétence
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </Grid>
              {service ? (
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="service-order-label">order</InputLabel>
                    <Select
                      labelId="service-order-label"
                      id="service-order-select"
                      value={order}
                      label="order"
                      onChange={(e) => setOrder(e.target.value)}
                    >
                      {Array.from(Array(nbType))?.map((_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid xs={12}>
                <TabsComponent
                  tabContent={tabContent}
                  errors={Object.keys(tabErrors)}
                />
              </Grid>
              <Grid>
                <Button variant="contained" onClick={onSubmit} color="success">
                  {!service ? "Create" : "Update"}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </BaseModal>
    </>
  );
}
